import { getDatabase, onValue, ref, update } from 'firebase/database'
import Swal from 'sweetalert2'
import { getWorkers } from '../services/workers'
import { getNominas } from '../services/nomina'
import {
  getArea,
  getPuestoByFirstName,
  getPuestoById,
  getWorkerById,
} from './workersFunctions'
import { database } from '../configs/firebase'
import { isWithinDateRange } from './deliveriesFunctions'
import { processNombre } from '../components/Deliveries/worker'

const getNominaById = (id) => {
  const nominas = getNominas()

  const nominaData = nominas.find((item) => item.id === id)

  return nominaData
}

export const checkWorkerArea = (idWorker, idNomina) => {
  const nomina = getNominaById(idNomina)

  const areaWorker = getArea(idWorker)

  let nodesToCheck = []
  if (areaWorker === '2') {
    nodesToCheck = [nomina[1]]
  } else if (areaWorker === '3' || areaWorker === '4') {
    nodesToCheck = [nomina[1], nomina[2]]
  } else {
    console.log(
      `No se requiere evaluación para el área del trabajador: ${areaWorker}`
    )
    return 0
  }

  // Días de la semana en orden correcto
  const daysOfWeek = [
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
    'domingo',
  ]

  // Ordenar los objetos dentro de `nodesToCheck`
  const sortedNodesToCheck = nodesToCheck.map((node) => {
    // Convertimos el objeto con días en un array de entradas
    const sortedDays = Object.entries(node).sort(([dayA], [dayB]) => {
      // Ordenamos según la posición en `daysOfWeek`
      return daysOfWeek.indexOf(dayA) - daysOfWeek.indexOf(dayB)
    })

    // Convertimos las entradas ordenadas de vuelta a un objeto
    return Object.fromEntries(sortedDays)
  })

  let salarios = []

  for (const [dayKey, dayNode] of Object.entries(sortedNodesToCheck)) {
    // Aseguramos que `dayNode` tiene nodos internos
    if (!dayNode || typeof dayNode !== 'object') continue

    // Recorremos cada sub-nodo (por ejemplo, lunes, martes) dentro de `dayNode`
    for (const [subDayKey, subDayNode] of Object.entries(dayNode)) {
      const trabajadoresNode = subDayNode?.trabajadores

      // Verificamos si `trabajadoresNode` existe y es un objeto
      if (!trabajadoresNode || typeof trabajadoresNode !== 'object') {
        console
          .log
          //`Trabajadores no encontrados o estructura inválida en el día: ${subDayKey}`
          ()
        continue
      }

      // Iteramos sobre los trabajadores dentro del día actual
      for (const [trabajadorKey, trabajadorData] of Object.entries(
        trabajadoresNode
      )) {
        // Verificamos si el trabajador tiene el ID que buscamos
        if (
          trabajadorData &&
          typeof trabajadorData === 'object' &&
          trabajadorData.id === idWorker
        ) {
          //console.log('Trabajador encontrado:', trabajadorData)
          salarios.push({
            key: subDayKey,
            salary: trabajadorData.salarioGenerado * 1,
          })
        }
      }
    }
  }

  return salarios
}

export const getStatusToString = (status) => {
  switch (status) {
    case '1':
      return 'ABIERTA'
    case '2':
      return 'CERRADA'
    case '3':
      return 'CANCELADA'
  }

  return ''
}

export const cancelNomina = (id) => {
  Swal.fire({
    icon: 'question',
    title: '¿Estas seguro de cancelar esta nómina',
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Sí, estoy seguro',
    denyButtonText: 'Cancelar',
    confirmButtonColor: '#28A745',
    customClass: {
      container: 'my-swal',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const db = getDatabase()

      update(ref(db, `nominas/${id}/`), {
        status: '3',
      })
      Swal.fire({
        title: 'Hecho',
        icon: 'success',
        text: 'Nómina cancelada exitosamente',
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1000,
        customClass: {
          container: 'my-swal',
        },
      })
    } else if (result.isDenied) {
      Swal.fire({
        title: 'Operación abortada',
        icon: 'info',
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1000,
        customClass: {
          container: 'my-swal',
        },
      })
    }
  })
}

export const closeNomina = (id) => {
  Swal.fire({
    icon: 'question',
    title: '¿Estas seguro de cerrar esta nómina',
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Sí, estoy seguro',
    denyButtonText: 'Cerrar',
    confirmButtonColor: '#28A745',
    customClass: {
      container: 'my-swal',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const db = getDatabase()

      update(ref(db, `nominas/${id}/`), {
        status: '2',
      })
      Swal.fire({
        title: 'Hecho',
        icon: 'success',
        text: 'Nómina cerrada exitosamente',
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1000,
        customClass: {
          container: 'my-swal',
        },
      })
    } else if (result.isDenied) {
      Swal.fire({
        title: 'Operación abortada',
        icon: 'info',
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1000,
        customClass: {
          container: 'my-swal',
        },
      })
    }
  })
}

export const checkStatus = (status) => {
  if (status === '2' || status === '3') {
    return true
  } else {
    return false
  }
}

export const getStatus = (id) => {
  const nomina = getNominaById(id)

  return nomina?.status || null
}

export const calculateProductionSalaries = (dayObject, productData) => {
  if (!dayObject || !productData) return dayObject

  // Obtenemos todos los trabajadores de la base de datos o fuente de datos
  const workers = getWorkers()

  dayObject.trabajadores
    .filter((workerInDay) => workerInDay !== null) // Filtramos trabajadores no nulos
    .forEach((workerInDay) => {
      // Encontramos al trabajador en la lista general que coincida con el ID
      const workerData = workers.find((worker) => worker.id === workerInDay.id)

      if (workerData) {
        // Extraemos el campo `variables` del trabajador encontrado
        const { variables } = workerData

        // Buscamos en `variables` el registro que coincida con el `producto` de `dayObject`
        const matchingVariable = variables.find(
          (variable) => variable.variable === dayObject.producto
        )

        // Buscar la variable que contiene las palabras 'salario' o 'sueldo'
        const salarioWorker = variables.find(
          (variable) =>
            variable.variable.toLowerCase().includes('salario') ||
            variable.variable.toLowerCase().includes('sueldo')
        )

        if (dayObject.salarioPDia === '1') {
          if (matchingVariable) {
            /* console.log(
              `Variable coincidente para el trabajador ${workerData.name}:`,
              matchingVariable
            ) */
            // Aquí es donde podemos continuar con la lógica de cálculo del salario

            //En caso de que el trabajador pertenezca al area de producción
            let salarioCalculado = matchingVariable.importe * dayObject.cantidad
            if (salarioCalculado < salarioWorker.importe) {
              salarioCalculado = salarioWorker.importe
            }
            // Asignamos el salario calculado al campo `salarioGenerado` de `workerInDay`
            workerInDay.salarioGenerado = salarioCalculado.toFixed(2)
          } else {
            /* console.log(
              `Producto ${dayObject.producto} no encontrado para el trabajador ${workerData.id}`
            ) */
          }
        } else {
          workerInDay.salarioGenerado = salarioWorker.importe.toFixed(2)
        }
      }
    })
  return dayObject
}

export const calculatePaletSalaries = (dayObject, productData, id) => {
  if (!dayObject || !productData) return dayObject

  // Obtenemos todos los trabajadores de la base de datos o fuente de datos
  const workers = getWorkers()

  Object.values(dayObject.trabajadores)
    .filter((workerInDay) => workerInDay !== null)
    .forEach((workerInDay) => {
      // Encontramos al trabajador en la lista general que coincida con el ID
      const workerData = workers.find((worker) => worker.id === workerInDay.id)

      if (workerData) {
        // Extraemos el campo `variables` del trabajador encontrado
        const { variables } = workerData

        // Buscamos en `variables` el registro que coincida con el `producto` de `dayObject`
        const matchingVariable = variables.find(
          (variable) => variable.variable === dayObject.producto
        )

        // Buscar la variable que contiene las palabras 'salario' o 'sueldo'
        const salarioWorker = variables.find(
          (variable) =>
            variable.variable.toLowerCase().includes('salario') ||
            variable.variable.toLowerCase().includes('sueldo')
        )

        // Buscar la variable que contiene las palabras 'pieza' o 'piezas'
        const comisionPiezas = variables.find(
          (variable) =>
            variable.variable.toLowerCase().includes('piezas') ||
            variable.variable.toLowerCase().includes('pieza')
        )

        if (dayObject.salarioPDia === '1') {
          if (matchingVariable) {
            /* console.log(
              `Variable coincidente para el trabajador ${workerData.id}:`,
              matchingVariable
            ) */
            // Aquí es donde podemos continuar con la lógica de cálculo del salario
            let salarioCalculado = 0

            //Se calcula el salario de paletizado
            salarioCalculado = matchingVariable.importe * dayObject.cantidad

            //Se calcula el salario + las horas trabajadas x día
            if (dayObject.horaSalarioPDia > 0) {
              salarioCalculado =
                salarioCalculado +
                parseFloat(dayObject.horaSalarioPDia) *
                  (salarioWorker.importe / 10)
            }

            //Se calcula el importe de las pzs cargadas o entregadas durante la semana y se suma al salarioCalculado
            /* salarioCalculado =
              salarioCalculado +
              getPzs(workerData.id, id) * comisionPiezas.importe */

            //Se verifica si el salario es menor al salario diario
            if (salarioCalculado < salarioWorker.importe) {
              salarioCalculado = salarioWorker.importe
            }

            // Asignamos el salario calculado al campo `salarioGenerado` de `workerInDay`
            workerInDay.salarioGenerado = salarioCalculado.toFixed(2)
          } else {
            /* console.log(
              `Producto ${dayObject.producto} no encontrado para el trabajador ${workerData.id}`
            ) */
          }
        } else {
          //Si el el salario x dia es sí
          workerInDay.salarioGenerado = salarioWorker.importe.toFixed(2)
        }
      }
    })
  return dayObject
}

export const calculateLogisticSalaries = (object, id) => {
  const worker = getWorkerById(object.trabajador.id)
  const wPuesto = getPuestoById(object.trabajador.id)

  let salario = 0

  const pzsVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('pieza') ||
      variable.variable.toLowerCase().includes('piezas')
  )
  const salarioVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('sueldo') ||
      variable.variable.toLowerCase().includes('salario')
  )
  const retenciones = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('retenciones')
  )

  if (pzsVariable) {
    const importePzs = pzsVariable.importe || 0
    salario += object.pzsCargadas * importePzs
  } else {
    console.warn("No se encontró una variable de 'pieza' en worker.variables.")
  }

  if (salarioVariable) {
    const importeSalario = salarioVariable.importe || 0

    salario +=
      object.diasLaborados * importeSalario +
      object.horasExtra * (importeSalario / 10)
  } else {
    console.warn(
      "No se encontró una variable de 'salario/sueldo' en worker.variables."
    )
  }
  // Sumar importes de los bonos solo si existen
  if (Array.isArray(object.bonos) && object.bonos.length > 0) {
    object.bonos.forEach((bono) => {
      // Buscar la variable que coincide con el bono actual
      const bonoVariable = worker.variables?.find(
        (variable) => variable.variable.toLowerCase() === bono.toLowerCase()
      )

      // Si existe el bono en worker.variables, sumamos su importe; si no, sumamos 0
      salario += bonoVariable ? bonoVariable.importe : 0
    })
  }

  if (object && wPuesto === '8') {
    // Buscar el valor de `importe` de la variable que contiene "kenworth" en su nombre
    const kenworthVariable = worker.variables?.find((variable) =>
      variable.variable.toLowerCase().includes('kenworth')
    )
    const camionVariable = worker.variables?.find((variable) =>
      variable.variable.toLowerCase().includes('camion')
    )
    const dobleVariable = worker.variables?.find((variable) =>
      variable.variable.toLowerCase().includes('doble')
    )
    const nissanVariable = worker.variables?.find((variable) =>
      variable.variable.toLowerCase().includes('nissan')
    )

    if (kenworthVariable) {
      const importeKenworth = kenworthVariable.importe || 0
      salario += object.viajes.kenworth * importeKenworth
    } else {
      console.warn(
        "No se encontró una variable de 'kenworth' en worker.variables."
      )
    }
    if (camionVariable) {
      const importeCamion = camionVariable.importe || 0
      salario += object.viajes.camion * importeCamion
    } else {
      console.warn(
        "No se encontró una variable de 'camion' en worker.variables."
      )
    }
    if (dobleVariable) {
      const importeDoble = dobleVariable.importe || 0
      salario += object.viajes.doble * importeDoble
    } else {
      console.warn(
        "No se encontró una variable de 'doble' en worker.variables."
      )
    }
    if (nissanVariable) {
      const importeNissan = nissanVariable.importe || 0
      salario += object.viajes.nissan * importeNissan
    } else {
      console.warn(
        "No se encontró una variable de 'nissan' en worker.variables."
      )
    }
  } else {
    console.log('error')
  }
  object.salarioSemanal = salario

  if (retenciones) {
    const importeRetencion = retenciones.importe || 0
    object.salarioNeto = salario + importeRetencion
  } else {
    console.warn(
      "No se encontró una variable de 'retención' en worker.variables."
    )
  }

  return object
}

export const calculateSalesSalaries = (object, comissions) => {
  const worker = getWorkerById(object.trabajador.id)

  let salario = 0
  let comision = 0

  const salarioVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('sueldo') ||
      variable.variable.toLowerCase().includes('salario')
  )
  const retenciones = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('retenciones')
  )

  const cpc30rVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('cpc30r') ||
      variable.variable.toLowerCase().includes('cemento')
  )

  cpc30rVariable
    ? (comision += comissions.cpc30r * cpc30rVariable.importe)
    : console.warn(
        "No se encontró una variable de 'cpc30r' en worker.variables"
      )

  const mortVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('mort') ||
      variable.variable.toLowerCase().includes('mortero')
  )

  mortVariable
    ? (comision += comissions.mort * mortVariable.importe)
    : console.warn("No se encontró una variable de 'mort' en worker.variables")

  const var38Variable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('var3/8') ||
      variable.variable.toLowerCase().includes('varilla')
  )

  var38Variable
    ? (comision += comissions.var38 * var38Variable.importe)
    : console.warn(
        "No se encontró una variable de 'var3/8' en worker.variables"
      )

  const blockVariable = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('block')
  )

  blockVariable
    ? (comision += comissions.block * blockVariable.importe)
    : console.warn("No se encontró una variable de 'block' en worker.variables")

  const tabiconVariable = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('tabicon')
  )

  tabiconVariable
    ? (comision += comissions.tabicon * tabiconVariable.importe)
    : console.warn(
        "No se encontró una variable de 'tabicon' en worker.variables"
      )

  const posteVariable = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('poste')
  )

  posteVariable
    ? (comision += comissions.poste * posteVariable.importe)
    : console.warn("No se encontró una variable de 'poste' en worker.variables")

  if (salarioVariable) {
    const importeSalario = salarioVariable.importe || 0

    salario +=
      object.diasLaborados * importeSalario +
      object.horasExtra * (importeSalario / 10)
  }

  // Sumar importes de los bonos solo si existen
  if (Array.isArray(object.bonos) && object.bonos.length > 0) {
    object.bonos.forEach((bono) => {
      // Buscar la variable que coincide con el bono actual
      const bonoVariable = worker.variables?.find(
        (variable) => variable.variable.toLowerCase() === bono.toLowerCase()
      )

      // Si existe el bono en worker.variables, sumamos su importe; si no, sumamos 0
      salario += bonoVariable ? bonoVariable.importe : 0
    })
  }

  object.salarioSemanal = salario + comision
  object.comisiones = comision

  if (retenciones) {
    const importeRetencion = retenciones.importe || 0
    object.salarioNeto = salario + comision + importeRetencion
  } else {
    console.warn(
      "No se encontró una variable de 'retención' en worker.variables."
    )
  }

  return object
}

export const calculateAdminSalaries = (object) => {
  const worker = getWorkerById(object.trabajador.id)

  let salario = 0

  const salarioVariable = worker.variables?.find(
    (variable) =>
      variable.variable.toLowerCase().includes('sueldo') ||
      variable.variable.toLowerCase().includes('salario')
  )
  const retenciones = worker.variables?.find((variable) =>
    variable.variable.toLowerCase().includes('retenciones')
  )

  if (salarioVariable) {
    const importeSalario = salarioVariable.importe || 0

    salario +=
      object.diasLaborados * importeSalario +
      object.horasExtra * (importeSalario / 10)
  }

  // Sumar importes de los bonos solo si existen
  if (Array.isArray(object.bonos) && object.bonos.length > 0) {
    object.bonos.forEach((bono) => {
      // Buscar la variable que coincide con el bono actual
      const bonoVariable = worker.variables?.find(
        (variable) => variable.variable.toLowerCase() === bono.toLowerCase()
      )

      // Si existe el bono en worker.variables, sumamos su importe; si no, sumamos 0
      salario += bonoVariable ? bonoVariable.importe : 0
    })
  }

  object.salarioSemanal = salario

  if (retenciones) {
    const importeRetencion = retenciones.importe || 0
    object.salarioNeto = salario + importeRetencion
  } else {
    console.warn(
      "No se encontró una variable de 'retención' en worker.variables."
    )
  }

  return object
}

export const getDeliveriesByDriver = (driverObject, id) => {
  const kenworth = []
  const camion = []
  const ford2014 = []
  const nissan = []
  const pzsCargadas = []

  const nomina = getNominaById(id)

  const nombreProcesado = processNombre(
    driverObject.trabajador.nombre.toLowerCase()
  )

  onValue(ref(database, 'entregas/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (isWithinDateRange(nomina, item.val())) {
        if (
          item.val().chofer.toLowerCase() === nombreProcesado &&
          getPuestoByFirstName(item.val().chofer.toLowerCase()) === '8'
        ) {
          switch (item.val().unidad) {
            case 'Kenworth':
              kenworth.push(1)
              break
            case 'International':
            case 'Camión Ford':
              camion.push(1)
              break
            case 'Camioneta Ford':
              ford2014.push(1)
              break
            case 'Nissan 2014':
              nissan.push(1)
              break
            case 'Recogen (Camioneta)':
            case 'Recogen (Camion)':
            case 'Recogen (Torton)':
              const productos = item.val().producto.split(', ')
              //Filtra los productos que no sean block o tabicon y extrae los numeros
              const numerosFiltrados = productos
                .filter(
                  (item) =>
                    !item.includes('CPC30R') &&
                    !item.includes('MORT') &&
                    !item.includes('VAR3/8') &&
                    !item.includes('CPC-MORT') &&
                    !item.includes('Block Macizo') &&
                    !item.includes('Poste 2x10x10') &&
                    !item.includes('Poste 2.5x10x10') &&
                    !item.includes('Poste 2x15x15') &&
                    !item.includes('Poste 2.5x15x15') &&
                    !item.includes('TC6') &&
                    !item.includes('TC8') &&
                    !item.includes('TC10') &&
                    !item.includes('TC12') &&
                    !item.includes('TC15') &&
                    !item.includes('TC18') &&
                    !item.includes('TC24') &&
                    !item.includes('Brocal')
                )
                .map((item) => parseInt(item))

              // Suma los números en el array resultante
              const sumaTotal = numerosFiltrados.reduce(
                (total, num) => total + num,
                0
              )
              pzsCargadas.push(sumaTotal)
              break
            default:
              break
          }
          driverObject.viajes.kenworth = kenworth.length
          driverObject.viajes.camion = camion.length
          driverObject.viajes.doble = ford2014.length
          driverObject.viajes.nissan = nissan.length
          driverObject.pzsCargadas = pzsCargadas.reduce(
            (total, num) => total + num,
            0
          )
        } else if (
          (item.val().chofer.toLowerCase() === nombreProcesado ||
            item.val().ayudante.toLowerCase() === nombreProcesado ||
            item.val().ayudante2.toLowerCase() === nombreProcesado) &&
          (item.val().unidad !== 'Nissan 2014' ||
            item.val().unidad.includes('Recogen'))
        ) {
          const productos = item.val().producto.split(', ')

          //Filtra los productos que no sean block o tabicon y extrae los numeros
          const numerosFiltrados = productos
            .filter(
              (item) =>
                !item.includes('CPC30R') &&
                !item.includes('MORT') &&
                !item.includes('VAR3/8') &&
                !item.includes('Poste 2x10x10') &&
                !item.includes('Poste 2.5x10x10') &&
                !item.includes('Poste 2x15x15') &&
                !item.includes('Poste 2.5x15x15') &&
                !item.includes('TC6') &&
                !item.includes('TC8') &&
                !item.includes('TC10') &&
                !item.includes('TC12') &&
                !item.includes('TC15') &&
                !item.includes('TC18') &&
                !item.includes('TC24') &&
                !item.includes('Brocal')
            )
            .map((item) => parseInt(item))

          // Suma los números en el array resultante
          const sumaTotal = numerosFiltrados.reduce(
            (total, num) => total + num,
            0
          )

          pzsCargadas.push(sumaTotal)

          driverObject.pzsCargadas = pzsCargadas.reduce(
            (total, num) => total + num,
            0
          )
        }
      }
    })
  })

  driverObject = calculateLogisticSalaries(driverObject, id)

  return driverObject
}

export const getComisionsLogisticWorker = (id, cantidad, index) => {
  const worker = getWorkerById(id)
  let totalComision = 0

  const variableComision =
    (Array.isArray(worker?.variables) &&
      worker.variables.find((variable) =>
        variable.variable?.toLowerCase().includes(index || '')
      )) ||
    null

  if (variableComision) {
    const importe = variableComision.importe || 0
    totalComision = importe * cantidad
  } else {
    /* console.warn(
      `No se encontró una variable de '${index}' en worker.variables.`
    ) */
  }

  return totalComision
}

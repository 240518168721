import { useState } from 'react'
import useTable from '../UI/Table'
import {
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from '@mui/material'
import Controls from '../UI/Controls'
import { Add, Delete, Edit, Search, Visibility } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import Swal from 'sweetalert2'
import { CustomDialog } from '../UI/Dialog'
import WorkersForm from './form'
import { deleteWorker, useWorkers } from '../../services/workers'
import { getPuesto } from '../../helpers/workersFunctions'
import { getAreaToString } from '../../helpers/reportfunctions'

const headCells = [
  { id: 'nombre', label: 'Nombre' },
  { id: 'area', label: 'Area' },
  { id: 'puesto', label: 'Puesto' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles(() => ({
  searchInput: {
    width: '100%',
  },
  buttonAdd: {
    left: '5px',
    backgroundColor: '#dec65e',
  },
  tableCell: {
    fontSize: '14px',
    fontFamily: 'Nunito',
  },
  '@media (max-width: 750px)': {
    buttonAdd: {
      left: '5px',
      backgroundColor: '#dec65e',
    },
  },

  toolBar: {
    justifyContent: 'space-between',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
}))

export const Workers = () => {
  const [workers] = useWorkers()

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState({})

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(workers, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  const handleClose = () => {
    setUpdate(null)
  }

  return (
    <>
      <div style={{ marginTop: '3%' }} className="with-navbar">
        <h2 className="h1">Administración de Trabajadores</h2>
      </div>
      <div className="form" style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar className={classes.toolBar}>
          <div
            className="medium-group"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Controls.Input
              variant="standard"
              className={classes.searchInput}
              placeholder="Buscar Trabajador"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
          <div
            className="medium-group"
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
                '@media (max-width 514px)': {
                  marginTop: '20px',
                },
              }}
              onClick={() => {
                setOpen(true)
                setUpdate(null)
              }}
            >
              Agregar
            </Button>
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((worker) => (
              <TableRow key={worker.id}>
                <TableCell data-label="Nombre: " className={classes.tableCell}>
                  {worker.nombre}
                </TableCell>
                <TableCell data-label="Area: " className={classes.tableCell}>
                  {getAreaToString(worker.area)}
                </TableCell>
                <TableCell data-label="Puesto: " className={classes.tableCell}>
                  {getPuesto(worker.puesto).toUpperCase()}
                </TableCell>
                <TableCell
                  data-label="Acciones: "
                  className={classes.tableCell}
                >
                  <IconButton
                    onClick={() => {
                      setUpdate(worker)
                      setOpen(true)
                    }}
                    sx={{
                      backgroundColor: 'orange',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkorange',
                        border: '1px solid orange',
                      },
                    }}
                  >
                    <Edit sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: '¿Estas seguro de eliminar este registro?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Sí',
                        denyButtonText: 'No',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteWorker(worker.id)
                          Swal.fire(
                            'Hecho',
                            'Registro eliminado exitosamente',
                            'success'
                          )
                        } else if (result.isDenied) {
                          Swal.fire('Operación abortada', '', 'info')
                        }
                      })
                    }}
                    sx={{
                      backgroundColor: 'red',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkred',
                        border: '1px solid red',
                      },
                    }}
                  >
                    <Delete sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Formulario Trabajadores"
          openPopup={open}
          setOpenPopup={setOpen}
          maxWidth="sm"
          onClose={handleClose}
        >
          <WorkersForm
            setOpenPopup={setOpen}
            update={update}
            setUpdate={setUpdate}
          />
        </CustomDialog>
      </div>
    </>
  )
}

import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { Add, Search } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { useClients } from '../../services/clients'

const headCells = [
  { id: 'id', label: 'ID' },
  { id: 'cliente', label: 'Cliente' },
  { id: 'domicilio', label: 'Domicilio' },
]

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    outline: '0',
    marginBottom: '20px',
    borderRadius: '20px',
    border: '0',
    backgroundColor: '#e9e9e9',
    fontSize: '14px',
    appearance: 'none',
    resize: 'none',
    padding: '2px',
  },
  input: {
    border: 'none',
    height: '2rem',
  },
  iconButton: {
    width: '2rem',
    height: '2rem',
    marginRight: '-10px',
  },
  selectButton: {
    backgroundColor: '#00544e',
    color: 'white',
    height: '30px',
    width: '30px',
    transition: '0.3s all ease',
    '&:hover': {
      backgroundColor: '#009696',
      border: '1px solid #00544e',
    },
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
}))

export const ClientsSelect = ({ setClient, setOpenPopup }) => {
  const [clients] = useClients()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(clients, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar>
          <div className="special-group">
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
              }}
              placeholder="Buscar Cliente"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((client) => (
              <TableRow
                key={client.id}
                onClick={() => {
                  setClient(client)
                  setOpenPopup(false)
                }}
              >
                <TableCell data-label="ID: ">{client.nc}</TableCell>
                <TableCell data-label="Nombre: ">{client.nombre}</TableCell>
                <TableCell
                  data-label="Domicilio: "
                  className={classes.tableCell}
                >
                  {client.calle + ' #' + client.numero + ', ' + client.colonia}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </div>
    </>
  )
}
